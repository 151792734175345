import React, {useCallback, useState} from "react"
import Base from "../components/base"
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/Seo";
import Gallery from "react-photo-gallery"
import Carousel, {Modal, ModalGateway} from "react-images"

const GalleryPage  =  ({pageContext}) => {
  const data = useStaticQuery(graphql`
      {
          site {
              siteMetadata {
                  ...SiteMeta
              }
          }
          img: allContentfulSlideshow {
              edges {
                  node {
                      pictures {
                          gatsbyImageData(width: 800)
                          contentful_id
                          createdAt
                      }
                  }
              }
          }
      }
  `);

  const media = data.img.edges[0].node.pictures.map((pic, i) => {
    const root = pic.gatsbyImageData;
    return {
      src: root.images.fallback.src,
      srcSet: root.images.sources[0].srcSet.split(','),
      sizes: [root.images.sources[0].sizes],
      width: root.width,
      height: root.height
    }
  });

  const [currentImg, setCurrentImg] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, {photo, index})=>{
    setCurrentImg(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImg(0);
    setViewerIsOpen(false);
  }

  return (<Base>
    <Seo article image={data.site.siteMetadata.defaultImage}
         description={data.site.siteMetadata.description}
         title="Bildergalerie"
         pathname={pageContext.slug}/>
      <Gallery margin={5} photos={media} onClick={openLightbox}/>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImg} views={media.map(x => ({
              ...x,
              srcset: x.srcSet,
              caption: x.title || ""
            }))}
            />
          </Modal>
        ): null}
      </ModalGateway>
  </Base>)
}
export default GalleryPage;